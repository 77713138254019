import React from 'react'
import Step1 from './Dakcheck/Step1';
import Step2 from './Dakcheck/Step2';
import Step3 from './Dakcheck/Step3';
import StepBereken from './Dakcheck/StepBereken';
import StepVriend from './Dakcheck/StepVriend';
import StepProcess from './Dakcheck/StepProcess';

class Dakcheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                'dakrichting': 'Z',
                'daklengte': 5,
                'dakbreedte': 6,
                'daktype': '0',
                'vriendnaam': '',
                'vriendemail': ''
            },
            errors: {
                'daklengte': false,
                'dakbreedte': false,
                'vriendnaam': false,
                'vriendemail': false
            },
            step: 1,
        };

        this.navigate = this.navigate.bind(this);
        this.resetDakcheck = this.resetDakcheck.bind(this);
    }

    handleChange = (event) => {

        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value
            }
        });

    }

    componentDidMount() {
        if (this.state.step === 6) {
            this.setState({isLoading: true});
        }
    }

    resetDakcheck() {
        this.setState({
            data: {
                'dakrichting': 'Z',
                'daklengte': 5,
                'dakbreedte': 6,
                'daktype': '0',
                'vriendnaam': '',
                'vriendemail': '',
                'vriendtelefoon': ''
            },
            errors: {
                'daklengte': false,
                'dakbreedte': false,
                'vriendnaam': false,
                'vriendemail': false,
                'vriendtelefoon': false
            },
            step: 1,
        });

    }

    navigate(change) {

        var regNumber = new RegExp('^\\d+$');
        var regName = new RegExp('^[a-zA-Z .,&()]{2,30}$');
        // eslint-disable-next-line no-useless-escape
        var regEmail = new RegExp('^[a-zA-Z0-9_+\.-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+.+[a-zA-Z0-9]$');

        if (this.state.step === 1) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    daklengte: !regNumber.test(this.state.data['daklengte']),
                    dakbreedte: !regNumber.test(this.state.data['dakbreedte'])
                }
            });

            if (!regNumber.test(this.state.data['daklengte']) || !regNumber.test(this.state.data['dakbreedte'])) {
                return false;
            }
        }

        if (this.state.step === 5 && (!regName.test(this.state.data['vriendnaam']) || !regEmail.test(this.state.data['vriendemail']))) {

            this.setState({
                errors: {
                    ...this.state.errors,
                    vriendnaam: !regName.test(this.state.data['vriendnaam']),
                    vriendemail: !regEmail.test(this.state.data['vriendemail'])
                }
            });

            return false;
        } else {

            this.setState({
                errors: {
                    ...this.state.errors,
                    vriendnaam: false,
                    vriendemail: false
                }
            });
        }

        this.setState({
            step: this.state.step + change,
        });
    }


    renderStep(step) {
        if (step === 1) {
            return <Step1 handleChange={this.handleChange} data={this.state.data} errors={this.state.errors}/>;
        }
        if (step === 2) {
            return <Step2 handleChange={this.handleChange} data={this.state.data}/>;
        }
        if (step === 3) {
            return <Step3 handleChange={this.handleChange} data={this.state.data}/>;
        }
        if (step === 4) {
            return <StepBereken data={this.state.data}/>;
        }
        if (step === 5) {
            return <StepVriend handleChange={this.handleChange} data={this.state.data} errors={this.state.errors}/>;
        }
        if (step === 6) {
            return <StepProcess data={this.state.data}
                                resetDakcheck={this.resetDakcheck}
                                navigate={this.navigate}
                                clearCache={this.props.clearCache}
                                GAevent={this.props.GAevent}
            />;
        }
    }

    render() {
        let navigation, buttonprevious, buttonnext, stepcounter;
        let buttonlabels = {
            1: 'Volgende',
            2: 'Volgende',
            3: 'Bereken',
            4: 'Vriend aanmelden',
            5: 'Verzenden'
        }

        if (this.state.step > 1 && this.state.step <= 5) {
            buttonprevious = <button href="#" className="backlink" onClick={() => this.navigate(-1)}>Vorige </button>
        }

        if (this.state.step <= 5) {
            buttonnext =
                <button className="button" onClick={() => this.navigate(1)}>{buttonlabels[this.state.step]}</button>
        }

        navigation = (
            <div className="periodnavigation">
                {buttonnext}
                {buttonprevious}
            </div>
        )

        if (this.state.step <= 3) {
            stepcounter = <h2>Stap {this.state.step}/3</h2>
        }

        return (

            <div className="contentWrapper dakcheck">
                <div className="content">
                    <h1>Dakcheck</h1>
                    {stepcounter}

                    <div className="dakcheck">
                        {this.renderStep(this.state.step)}
                    </div>

                    {navigation}
                </div>
            </div>

        );
    }
}

export default Dakcheck
