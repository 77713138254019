import React from 'react';
import loadingGif from '../assets/images/loading.gif'
import config from '../../config.json'
import Accordion from "./Accordion";

class Leads extends React.Component {

    componentDidMount() {
	    this.props.fetchFriends();
    }

	AanmeldButtonClick() {
		this.props.changePage('addFriend');
		this.props.GAevent('Vrienden', 'Button Vriend aanmelden', 'Clicked');
	}

	RequestPayoutButtonClick() {
		this.props.changePage('requestPayout');
		this.props.GAevent('Vrienden', 'Button Vriend aanmelden', 'Clicked');
	}

	render() {
		const {totalamount, totaluitgekeerd, payment_status} = this.props;
		const isLoading = this.props.isLoading;
		let totaltegoed = totalamount - totaluitgekeerd;
		let textuitkeren = '';
		let buttonuitkeren = '';

		if (isLoading) {
            return <LoadingDiv />;
        }

		if (payment_status !== config.PAYMENT_STATUS) {
			if (this.props.friends) {
				textuitkeren = <p className="text-center">In totaal heeft u {this.props.toCurrency(this.props.totalamount)} verdiend, waarvan {this.props.toCurrency(this.props.totaluitgekeerd)} reeds is uitgekeerd.</p>
			} else {
				textuitkeren = <p className="text-center">U heeft nog geen vrienden aangemeld voor een Zekerzon.</p>
			}

			if (totaltegoed > 0) {
				buttonuitkeren = <button className="button" onClick={() => this.RequestPayoutButtonClick()}>€{totaltegoed},- uitkeren</button>
				// buttonuitkeren = 'Neem contact op met De Zekerzon om het openstaande tegoed uit te keren.'
			}
		} else {
			textuitkeren = <p className="text-center">U heeft een uitkering van uw tegoed aangevraagd. Tot dit verzoek is afgehandeld, kunt u geen nieuwe aanvraag doen.</p>
		}

		return (
			<div>
				<p className="text-center">Door vrienden te wijzen op de voordelen van Zekerzon kunt u nu minimaal {this.props.toCurrency(this.props.current_reward)} verdienen!</p>
				<button className="button" onClick={() => this.AanmeldButtonClick()}>Vriend aanmelden</button>
				<Accordion
					accordionTitle="Aangedragen klanten"
					accordionContent={this.props.friends}
				/>
				{textuitkeren}
				{buttonuitkeren}
			</div>
		);
	}
}

class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Loading" />
            </div>
        )
    }
}

export default Leads
