import React from 'react'

class Logout extends React.Component { 

	logout = () => {
		localStorage.clear();
		this.setState({
			loggedIn: false
		});
		
		this.props.handleLogout();
		this.props.GAevent('General', 'Logout', 'Success');
		this.props.GApageview('/');
	}
	
	render() {
		
		return (
			<div className="Logout">
				<div>
				  <h2>Loginstatus</h2>
				  <p>U bent op dit moment ingelogd als <strong>{this.props.user_name}</strong>.</p>
				  <button className="button" onClick={() => this.logout() }>Uitloggen</button>
			  </div>
		  </div>
		)	

  	}
}

export default Logout
