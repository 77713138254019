import React from 'react'
import { Link } from 'react-router-dom';
import logo from './assets/images/logo.png';
import Account from './Header/Account';
import config from '../config.json';

// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component {

	render () {
		return (
			<header className="mb-3">
		    	<Link to="/"><img src={logo} alt={config.PROJECT_NAME}/></Link>
				<Account />
			</header>
		)
	}


}

export default Header
