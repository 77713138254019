import React from 'react';

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: 'block',
            transform: ''
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const newDisplay = this.state.display === 'block' ? 'none' : 'block';
        const newTransform = this.state.transform === 'rotate(-90deg)' ? '' : 'rotate(-90deg)';
        this.setState({
            display: newDisplay,
            transform: newTransform
        });
    }

    render() {
        return(
            <div className="accordion">
                <div className="accordionHeader" onClick={this.toggle}>
                    <span>{this.props.accordionTitle}</span>
                    <svg style={{ transform: this.state.transform }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
                        <path d="M0-.75h24v24H0z" fill="none"/>
                    </svg>
                </div>
                <div className="accordionContent" style={{ display: this.state.display }}>
                    <span>{this.props.accordionContent}</span>
                </div>
            </div>
        );
    }
}

export default Accordion;
