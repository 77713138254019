import React from 'react'
import loadingGif from '../assets/images/loading.gif'


class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				'oldpassword': '',
				'newpassword1': '',
				'newpassword2': ''
			},
			errors: {
				'oldpassword': false,
				'newpassword1': false,
				'newpassword2': false
			},
			errornotice: ''
		};
	}

	handleInputChange = (event) => {

			this.setState({
				data: {...this.state.data,
					[event.target.name]: event.target.value
				}
			});

		}

	handleSubmit = () => {

		this.setState({isLoading: true});

		if (this.state.data.oldpassword.length < 1) {
			this.setState({
				errors: {
					'oldpassword': true,
					'newpassword1': false,
					'newpassword2': false
				},
				errornotice: 'U dient uw oude wachtwoord in te vullen.',
				isLoading: false
			})
			return;
		}

		else if (this.state.data.newpassword1.length < 8) {
			this.setState({
				errors: {
					'oldpassword': false,
					'newpassword1': true,
					'newpassword2': false
				},
				errornotice: 'Uw nieuwe wachtwoord moet uit minimaal 8 tekens bestaan.',
				isLoading: false
			})
			return;
		}

		else if (this.state.data.newpassword1 !== this.state.data.newpassword2) {
			this.setState({
				errors: {
					'oldpassword': false,
					'newpassword1': true,
					'newpassword2': true
				},
				errornotice: 'Uw nieuwe wachtwoorden komen niet overeen.',
				isLoading: false
			})
			return;
		}

		else {
			let username = localStorage.getItem('user_name')
			let token = localStorage.getItem('token')
			let url = process.env.REACT_APP_API_URL + '?function=changePassword&user=' + username + '&token=' +  token + '&old_password=' + this.state.data.oldpassword + '&new_password=' + this.state.data.newpassword1;

			fetch(url)
			.then(response => response.json())
			.then((data) => {
				if (data.Result === 'Password changed') {
					this.setState({
						data: {
							'oldpassword': '',
							'newpassword1': '',
							'newpassword2': ''
						},
						errors: {
							'oldpassword': false,
							'newpassword1': false,
							'newpassword2': false
						},
						errornotice: '',
						isLoading: false
					});
					this.props.changePage('successPage');
				} else {
					this.setState({
						errors: {
							'oldpassword': true,
							'newpassword1': false,
							'newpassword2': false
						},
						errornotice: 'Uw oude wachtwoord is niet correct.',
						isLoading: false
					});
				}
			}
			)

		}



	}

	render() {

		const isLoading = this.state.isLoading;

		if (isLoading) {
			return <LoadingDiv />;
		}

		return (
			<div>
				<div className="changePassword">
					<form onSubmit={this.handleSubmit} className="changePassword">
						<p>Vul onderstaande gegevens in om uw wachtwoord aan te passen.</p>

						<div className="error">{this.state.errornotice}</div>

						<div className="inputRow">
							<label>
								Huidig wachtwoord<br />
							</label>

							<div className="inputField">
								<input name="oldpassword" className={this.state.errors['oldpassword'] ? "error" : ""} type="password" value={this.state.data['oldpassword']} onChange={this.handleInputChange} />
							</div>
						</div>
						<div className="inputRow">
							<label>
								Nieuw wachtwoord<br />
							</label>

							<div className="inputField">
								<input name="newpassword1" className={this.state.errors['newpassword1'] ? "error" : ""} type="password" value={this.state.data['newpassword1']} onChange={this.handleInputChange} />
							</div>
						</div>
						<div className="inputRow">
							<label>
								Herhaal nieuw wachtwoord<br />
							</label>

							<div className="inputField">
								<input name="newpassword2" className={this.state.errors['newpassword2'] ? "error" : ""} type="password" value={this.state.data['newpassword2']} onChange={this.handleInputChange} />
							</div>
						</div>
					</form>

					<button className="button" onClick={() => this.handleSubmit() }>Wijzigen</button>
					<button href="#" className="backlink" onClick={() => this.props.changePage('overview') }>Terug</button>

				</div>
			</div>
		)
   }
}

class LoadingDiv extends React.Component {
	render() {
		return (
			<div className="LoadingDiv">
				<img src={loadingGif} alt="Loading" />
			</div>
		)
	}
}

export default ChangePassword
