import React from 'react'
import { NavLink } from 'react-router-dom'

class Footer extends React.Component {
	componentDidMount() {
		this.props.GApageview();
	}
	render () {
        return (
		  <footer>
		    <nav>
		      <ul onClick={() => this.props.GApageview()}>
		        <li><NavLink exact={true} activeClassName='active' className='installaties' to='/installaties'><span></span>Installaties</NavLink></li>
		        <li><NavLink exact={true} activeClassName='active' className='opbrengst' to='/opbrengst'><span></span>Opbrengst</NavLink></li>
		        {/*<li><NavLink exact={true} activeClassName='active' className='dakcheck' to='/dakcheck'><span></span>Dakcheck</NavLink></li>*/}
		        {/*<li><NavLink exact={true} activeClassName='active' className='vrienden' to='/vrienden'><span></span>Vrienden</NavLink></li>   */}
		      </ul>
		    </nav>
		  </footer>
	)}
}

export default Footer
