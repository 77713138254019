import React from 'react'
import loadingGif from '../assets/images/loading.gif'

class ProcessFriend extends React.Component { 
	constructor(props) {
	    super(props);
	    this.state = {
            isLoading: true,
            data: {}
        }
	    
	}
	  
	handleInputChange(event) {
		this.props.handleChange(event);
	}
	
	processProspect() {
		
		let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')
        let url = process.env.REACT_APP_API_URL + '?function=addProspect&user=' + username + '&token=' +  token ;
		
		var call = url + 
			'&accountname=' + this.props.data.vriendnaam +
			'&email=' + this.props.data.vriendemail + 
			'&telefoon=' + this.props.data.vriendtelefoon
			; 
		
		fetch(call)
			.then(response => response.json())
	        .then(data => 
	        	this.setState({  
                	data: data.Result,
					isLoading: false
                })
            ).then(data =>
	            this.processGAevent()
            ).then(data =>
	            this.props.clearCache('getProspects', false)
            );

	}
	
	componentDidMount() {
		this.processProspect();
	}
	
	processGAevent() {
		if (this.state.data.Error) {
			this.props.GAevent('Vrienden', 'Vriend aangemeld', 'Existing lead');
		} else {
			this.props.GAevent('Vrienden', 'Vriend aangemeld', 'Success');
		}
	}
	
	resetAddFriend = () => {
		this.props.resetAddFriend();
	}

	render() {
		
		const {isLoading} = this.state;
		const errors = {
			'Email already exists in VTiger': 'Deze persoon is al bij ons bekend, wij zullen opnieuw contact opnemen. Uw kans op ambassadeursvergoeding voor deze klant komt te vervallen.'
		}
		
		if (isLoading) {
            return <LoadingDiv />;
        }
        

		if (this.state.data.Error) {
			return <div className="error">
						<p>{errors[this.state.data.Error]}</p>
						<p><button className="backlink" onClick={() => this.props.changePage('addFriend')}>Terug</button></p>
						<p><button className="backlink" onClick={() => this.resetAddFriend() }>Overzicht</button></p>
					</div>
		} else {
			return <div>Dank u wel voor het aanbrengen van deze lead! We gaan contact opnemen met {this.props.data.vriendnaam}, u kunt in het overzicht in deze app bijhouden van de status is.</div>
		}

	}
}

class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Loading" />
            </div>
        )
    }
}

export default ProcessFriend
