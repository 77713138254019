import React from 'react'
import Logout from './Account/Logout'
import ChangePassword from './Account/ChangePassword'


class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'overview',
        };
    }

    changePage = (page) => {

        this.setState({page: page});
    }


    render() {

        if (this.state.page === 'successPage') {

            return (

                <div className="contentWrapper">
                    <div className="content">
                        <h1>Uw account</h1>
                        <div className="success">Uw wachtwoord is veranderd.</div>
                        <button href="#" className="backlink" onClick={() => this.changePage('overview')}>Terug</button>

                    </div>
                </div>

            )

        } else if (this.state.page === 'changePassword') {

            return (

                <div className="contentWrapper">
                    <div className="content">
                        <h1>Uw account</h1>
                        <ChangePassword
                            changePage={this.changePage}
                        />
                    </div>
                </div>

            );

        } else {

            return (

                <div className="contentWrapper">
                    <div className="content">
                        <h1>Uw account</h1>
                        <div className="changePassword">
                            <div>

                                <h2>Uw gegevens</h2>
                                <strong>Naam:</strong><br/>
                                {this.props.fullname}<br/><br/>

                                <strong>Email:</strong><br/>
                                {this.props.email}<br/><br/>

                                <strong>Adres:</strong><br/>
                                {this.props.address}<br/><br/>

                                <strong>Wachtwoord:</strong><br/>
                                ********<br/>

                                <button className="button" onClick={() => this.changePage('changePassword')}>Wijzig
                                    wachtwoord
                                </button>
                            </div>
                        </div>

                        <Logout
                            handleLogout={this.props.handleLogout}
                            user_name={this.props.user_name}
                            GAevent={this.props.GAevent}
                            GApageview={this.props.GApageview}
                        />
                    </div>
                </div>

            );

        }
    }
}

export default Account
