import React from 'react'
import ReactGA from 'react-ga';
import Main from './components/Main'
import logo from './components/assets/images/logo.png';
import './components/assets/css/App.css'
import './components/assets/css/Mobile.css'
import './components/assets/css/Tablet.css'
import './components/assets/css/Desktop.css'
import config from "./config.json";


class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user_name: null,
			api_type: null,
			data: {},
			isLoggedin: false,
			error: null,
			response:null,
			errors: {username: false, password: false},
			key: Math.random(),
			pathname: '',
			isLoading: true
		};
		this.GAinit();
	}

	componentDidMount() {
		let user_name = localStorage.getItem('user_name');
		let token = localStorage.getItem('token');

		this.checkToken(user_name, token);

		if (this.state.isLoggedin !== true) {
			this.GApageview('/');
		}
	}

	GAinit = () => {

		ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

	};

	GApageview = (location = null) => {

		let currentpathname = (location) ? location : window.location.pathname + window.location.search;
		if (currentpathname !== this.state.pathname) {
			ReactGA.pageview(currentpathname);
			this.setState({pathname: currentpathname});
	    }

	}

	GAevent = (category = 'Unknown', action = 'Unknown', label = 'Unknown') => {

		ReactGA.event({
			category: category,
			action: action,
			label: label
		});

	}

	clearCache = (type, refresh=true) => {

	   	var cachedStorage = JSON.parse(localStorage.getItem('cache'));
        var newCache = '';

        if (typeof type != "undefined" && cachedStorage != null) {
	        newCache = cachedStorage.filter(call => call.type !== type);
    		localStorage.setItem('cache', JSON.stringify(newCache));
		} else {
			localStorage.removeItem('cache');
		}

		if (refresh) {
			this.setState({ key: Math.random() });
		}

	}

	clearCacheByUrl = (url) => {
		var cachedStorage = JSON.parse(localStorage.getItem('cache'));
	    var newCache = cachedStorage.filter(call => call.url !== url);
		localStorage.setItem('cache', JSON.stringify(newCache));
	}

	setCache = (cache, lifetime = 2592000) => {
		//standard lifetime = 60*60*24*30 = 30 days

		if (Object.keys(cache).length === 0 ) { return; }

        let timestamp = Math.floor(Date.now() / 1000);

		cache['lifetime'] = timestamp+lifetime;

		let url = cache['url'].split("?")[1];
		const urlParams = new URLSearchParams(url);
		cache['type'] = urlParams.get('function');

		var localCache = JSON.parse(localStorage.getItem('cache'))
		var newCache = (localCache) ? [...localCache, cache] : [cache];

		localStorage.setItem('cache', JSON.stringify(newCache));

	}

	getCache = (url) => {

		var cachedStorage = JSON.parse(localStorage.getItem('cache'));

		let timestamp = Math.floor(Date.now() / 1000);

		if (cachedStorage) {
			var cached = cachedStorage.filter(call => call.url === url);
			if (cached.length > 0) {
				if (cached[0].lifetime > timestamp) {
					return cached[0].response;
				} else {
					this.clearCacheByUrl(url);
				}
			}
		}

		return false;
	}

	handleLogout = () => {
		this.setState({
			user_name: '',
			isLoggedin: false,
			errors: {},
			error: 'U bent uitgelogd.'

		});
	}

	handleChange = (event) => {

		this.setState({
			data: {
				...this.state.data,
				[event.target.name]: event.target.value
			},
			errors: {
				...this.state.errors,
				[event.target.name]: false
			}
		});

	}

	handleSubmit = async e => {
	    e.preventDefault();
	    // make api call

	    if (!this.state.data.username) {
		    this.setState({
				errors: {
					...this.state.errors,
					username: true
				}
			});

			return false;
	    }

	    if (!this.state.data.password) {
		    this.setState({
				errors: {
					...this.state.errors,
					password: true
				}
			});

			return false;
	    }

	    let url = process.env.REACT_APP_API_URL + '?function=getToken&user=' + this.state.data.username + '&pass=' + this.state.data.password;

		fetch(url)
			.then(response => response.json())
	        .then(data =>
	        	this.setState({
                	response: data
                })
            )
            .then(data =>
	        	this.processLogin()
	        );

		this.setState({
			isLoggedin: false
		});
	};


	checkToken = (user_name = '', token = '') => {

		let url = process.env.REACT_APP_API_URL + '?function=checkToken&user=' + user_name + '&token=' + token;

		fetch(url)
		.then(response => response.json())
		.then(data => {
			if (data.Success === true) {
				if (data.Result === true) {
					this.setState({
						isLoggedin: true,
						user_name: user_name,
						fullname: data.Fullname,
						address: data.Address,
						email: data.Email,
						api_type: data.ApiType,
						isLoading: false
					})
				}
			}
		})
		.then(data => {
			this.setState({
				isLoading: false
			})
		});
	}

	processLogin = response => {
	  	if (!this.state.response.Success) {
		  	this.setState({
			  	error: 'Incorrecte gebruikernaam/wachtwoord combinatie.'
			});
	  	} else {
			  localStorage.setItem('token', this.state.response.Result);
			  localStorage.setItem('user_name', this.state.data.username);

		  	this.setState({
				user_name: this.state.data.username,
				fullname: this.state.response.Fullname,
				address: this.state.response.Address,
				email: this.state.response.Email,
				api_type: this.state.response.ApiType,
				data: {},
				error: null,
				token: this.state.response.Result,
				response: null,
				isLoggedin: true
			});
	  	}
	}

	render () {

		const {isLoading} = this.state;

		if (this.state.isLoggedin) {
		    return (
		      	<div className="wrapper">
		        	<Main
		        		handleLogout={this.handleLogout}
		        		isLoggedin={this.state.isLoggedin}
		        		user_name={this.state.user_name}
						fullname={this.state.fullname}
						address={this.state.address}
						email={this.state.email}
		        		api_type={this.state.api_type}
		        		token={this.state.token}
		        		setCache={this.setCache}
						getCache={this.getCache}
						clearCache={this.clearCache}
						clearCacheNew={this.clearCacheNew}
						key={this.state.key}
						GApageview={this.GApageview}
						GAevent={this.GAevent}
		        	 />
				</div>
		    )
		}

		if (isLoading) {
			return <LoadingDiv />;
		}

		return (
			<div className="loginscreen">
				<div className="logo">
					<img src={logo} alt={config.PROJECT_NAME}/>
				</div>

				<form className="loginform" onSubmit={this.handleSubmit}>

					<div className="error">{this.state.error}</div>


					<input name="username" className={this.state.errors['username'] ? "error" : ""} type="text" onChange={this.handleChange} placeholder="Uw gebruikersnaam: (Vb. 1234AB1)" />
					<input name="password" className={this.state.errors['password'] ? "error" : ""} type="password" onChange={this.handleChange} placeholder="Uw wachtwoord"/>

					<button type="submit">Login</button>

				</form>
				<div className="meta">
				  	<a href="https://www.zekerzon.nl/modx/forgot-password.html">Wachtwoord vergeten</a>
				</div>
			</div>
		);
	}
}

class LoadingDiv extends React.Component {
	render() {
		return (
			<div className="loginscreen">
				<div className="logo">
					<img src={logo} alt={config.PROJECT_NAME}/>
				</div>
				<div className="loadinghome">Bezig met laden...</div>
			</div>
		)
	}
}

export default App
